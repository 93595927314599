@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
    @font-face {
        font-family: quicksand;
        font-weight: 300;
        src: url("fonts/Quicksand-Light.ttf");
    }
    @font-face {
        font-family: quicksand;
        font-weight: 400;
        src: url("fonts/Quicksand-Regular.ttf");
    }
    @font-face {
        font-family: quicksand;
        font-weight: 500;
        src: url("fonts/Quicksand-Medium.ttf");
    }
    @font-face {
        font-family: quicksand;
        font-weight: 600;
        src: url("fonts/Quicksand-SemiBold.ttf");
    }
    @font-face {
        font-family: quicksand;
        font-weight: 700;
        src: url("fonts/Quicksand-Bold.ttf");
    }

    body {
        background-color: transparent !important;
    }
}

@layer utilities {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .text-divider {
        display: flex;
        align-items: center;
        --text-divider-gap: 1rem;
    }

    .text-divider::before,
    .text-divider::after {
        content: '';
        height: 1px;
        background-color: silver;
        flex-grow: 1;
    }

    .text-divider::before {
        margin-right: var(--text-divider-gap);
    }

    .text-divider::after {
        margin-left: var(--text-divider-gap);
    }

    .bg-schmeckn-normal {
        background: #0063a9;
    }

    .bg-schmeckn-green {
        background: #45b8af;
    }

    .text-schmeckn-normal {
        background: #0063a9;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .text-schmeckn-green {
        background: #45b8af;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .icon-text-schmeckn-blue {
        color: #0063a9;
    }

    .icon-text-schmeckn-green {
        color: #45b8af;
    }

    .bg-schmeckn-login {
        background: linear-gradient(90deg, #3eb0f6 0%, #58c798 100%);
    }

    .dynamic-delete-button {
        position: relative;
        top: 4px;
        margin: 0 8px;
        font-size: 24px;
        cursor: pointer;
        transition: all 0.3s;
    }

    .dynamic-delete-button:hover {
        color: #5b5b5b;
    }

    .expanded-order-details > td {
        padding: 0 !important;
    }
}

@layer responsive {
}

svg {
    vertical-align: baseline;
}

.ant-col-rtl .login-form-forgot {
    float: left;
}

.row-dragging {
    background: #fafafa;
    border: 1px solid #ccc;
}

.row-dragging td {
    padding: 16px;
}

.row-dragging .drag-visible {
    visibility: visible;
}

.ant-table-expanded-row > .ant-table-cell {
    padding: 0;
}